<template>
    <ContentRenderer :value="data">
        <ContentRendererMarkdown :value="data"/>
    </ContentRenderer>
</template>

<script setup lang="ts">
const route = useRoute();
const { data } = await useAsyncData('page-data', () => queryContent(route.fullPath).findOne());
useContentHead(data);
</script>
